import React, { useRef, useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import Particles from 'react-particles-js';
import { gsap } from "gsap";

import { 
  makeStyles,
  Container,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // background: 'linear-gradient(to left,#00a4e2,#101c5b)',
    background: 'linear-gradient(200deg, #2AA7DE, #663399)',
    position: 'relative',
    height: '100vh',
    width: '100%',
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    padding: '70px 0',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    flexDirection: 'column',
  },
  particles: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    content: "''",
    top: 0,
    zIndex: 0,
  },
  containerText: {
    overflow: 'hidden',
  },
  containerTitle: {
    marginBottom: 'auto',
    overflow: 'hidden',
  },
  title: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '1.8em',
    textShadow: '3px 2px 5px rgba(0, 0, 0, 0.3)',
    letterSpacing: '4px',
    marginBottom: 'auto',
    marginTop: '100px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '2em',
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '1.7em',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'SVN Aguda',
    fontSize: '1.8em',
    color: '#fff',
    textShadow: '7px 2px 5px rgba(0, 0, 0, 0.3)',
    

    [theme.breakpoints.up('sm')]: {
      fontSize: '2em',
      letterSpacing: '5px',
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '3em',
      letterSpacing: '5px',
      lineHeight: 0,
    },
  },
}), { name: 'iNeoHero' });

const HeroServices = () => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const titleRef = useRef(null);
  const text1Ref = useRef(null);
  const text2Ref = useRef(null);
  const text3Ref = useRef(null);

  const particlesParams = {
    "particles": {
      "number": {
        "value": matches ? '40' : '20'
      },
      "size": {
        "value": 3
      }
    },
    "interactivity": {
      "events": {
        "onhover": {
          "enable": false,
          "mode": "repulse"
        }
      }
    }
  }

  useEffect(() => {
    if (text1Ref.current && text2Ref.current) {
      const gsapAni = {
        y: 200,
        ease: 'power4.out',
        delay: 0.3,
        skewY: 10,
        stagger: {
          amount: 0.4,
        }
      };

      gsap.from(titleRef.current, gsapAni);
      gsap.from(text1Ref.current, {
        ...gsapAni,
        duration: 1,
      });
      gsap.from(text2Ref.current, {
        ...gsapAni,
        duration: 1.5,
      });
      gsap.from(text3Ref.current, {
        ...gsapAni,
        duration: 2,
      });

    }
  }, [intl]);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.container}>
          {/* <div className={classes.containerTitle}>
            <p ref={titleRef} className={classes.title}>let's talk about our services.</p>
          </div> */}
          <div className={classes.content}>
            <div className={classes.containerText}>
              <p ref={text1Ref} className={classes.text}>we design &amp; develop</p>
            </div>
            <div className={classes.containerText}>
              <p ref={text2Ref} className={classes.text}>innovative products &amp; services</p>
            </div>
            <div className={classes.containerText}>
              <p ref={text3Ref} className={classes.text}>for IoT ecosystem</p>
            </div>
          </div>
        </div>
      </Container>
      <Particles
        className={classes.particles}
        params={particlesParams} />
    </div>
  )
}

export default HeroServices;
